{
  "name": "xor4-app",
  "version": "4.13.0",
  "description": "",
  "scripts": {
    "check": "tsc --noEmit",
    "check:watch": "tsc --noEmit --watch",
    "client": "parcel -p 8080 src/index.html --https --no-cache",
    "start": "npm run client & npm run check:watch && fg",
    "build": "parcel build src/index.html ../game/*.ts",
    "lint": "eslint"
  },
  "author": "kareniel",
  "license": "MIT",
  "dependencies": {
    "@vue/runtime-core": "^3.2.26",
    "ethers": "^5.5.2",
    "events": "^3.3.0",
    "twemoji": "^13.1.0",
    "vue": "^3.2.26",
    "vue-router": "^4.0.12",
    "xor4-cli": "0.0.0",
    "xor4-game": "0.0.0",
    "xor4-interpreter": "0.0.0",
    "xor4-levels": "0.0.0",
    "xor4-lib": "0.0.0",
    "xterm": "^4.15.0",
    "xterm-addon-attach": "^0.6.0",
    "xterm-addon-fit": "^0.5.0"
  },
  "devDependencies": {
    "@parcel/packager-ts": "^2.0.1",
    "@parcel/transformer-raw": "^2.0.1",
    "@parcel/transformer-typescript-types": "^2.0.1",
    "@parcel/transformer-vue": "^2.0.1",
    "@parcel/validator-typescript": "^2.0.1",
    "@types/node": "^17.0.8",
    "@typescript-eslint/eslint-plugin": "^5.9.0",
    "@typescript-eslint/parser": "^5.9.1",
    "eslint": "^8.6.0",
    "eslint-plugin-vue": "^8.2.0",
    "parcel": "^2.0.1",
    "vue-eslint-parser": "^8.0.1"
  }
}
